import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { ResponseStatus } from "../../app/store/store";
import { rankScores } from "../../shared/util/utils";
import { getUserListByIds } from "../../api/User.api";
import { saveNight, saveRun, saveScores, saveShows, saveUsers, selectNightNumbers, selectNightShow, selectRankedUsersObj, selectRun, selectScoresByUser } from "./Leaderboard.store";
import { getRunById } from "../../api/Runs.api";
import { Run, RunID } from "../../types/Run.type";
import { User, UserID } from "../../types/User.type";
import {RunInfo} from "../../shared/component/RunInfo";
import { Link, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../shared/icons/LoadingSpinner";
import LeaderboardInfo from "./LeaderboardInfo";
import { ReactComponent as LeftArrow } from "../../shared/icons/LeftArrow.svg";
import { Guess } from "../../types/Guess.type";
import { getScoresForRun } from "../../api/Scores.api";
import { getShowsForRun } from "../../api/Shows.api";
import "./Leaderboard.scss";
import { BackArrow } from "../../app/App.router";
import { Show } from "../../types/Show.type";
import { checkUserCookie } from "../Authentication/Authentication.store";

const LeaderboardContainer: React.FC = () => {
    const navigate = useNavigate();
    const { runId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const night = searchParams.get('night');
    const rankedUsers: ({user: User, points: number})[] = useSelector(selectRankedUsersObj);
    const scoresByUser: Record<UserID, Guess[]> = useSelector(selectScoresByUser);
    const run: Run | null = useSelector(selectRun);
    const nightNumbers: number[] = useSelector(selectNightNumbers);
    const nightShow: Show | null = useSelector(selectNightShow);
    
    const [ status, setStatus ] = useState<"loading" | "loaded" | "error">("loading");
    const [ error, setError ] = useState<string | null>(null);
    const dispatch = useDispatch();

    const collectLeaderboardInfo = async (runId: RunID) => {
        const scores = await getScoresForRun(runId);
        if (scores === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        const rankedUsers = rankScores(scores);
        const userIds = rankedUsers.map(user => user.userId);
        const users = await getUserListByIds(userIds);
        if (users === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        const run = await getRunById(runId);
        if (run === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        } else if (run === ResponseStatus.NotFound) {
            setError("Run not found");
            setStatus("error");
            return;
        }
        const shows = await getShowsForRun(runId);
        if (shows === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        dispatch(saveScores(scores));
        dispatch(saveUsers(users));
        dispatch(saveRun(run));
        dispatch(saveShows(shows));
        setStatus("loaded");
    }

    useEffect(() => {
        if (!runId || isNaN(parseInt(runId))) {
            setError("Run not found");
        } else {
            dispatch(checkUserCookie());
            collectLeaderboardInfo(parseInt(runId));
        }

    }, [ runId, dispatch ]);

    useEffect(() => {
        if (night && !isNaN(parseInt(night))) {
            dispatch(saveNight(parseInt(night)));
        }
    }, [ night, dispatch ]);

    const chooseNight = (night: number | null) => {
        if (night !== null) {
            setSearchParams({night: night.toString()});
        } else {
            setSearchParams();
        }
        dispatch(saveNight(night));
    }

    return (
        <div id="leaderboard-page">
            <div className="column--centered">
                <div className="header-container">
                    <p className="header">Leaderboard</p>
                    <BackArrow />
                </div>
                {
                    status === 'loading' &&
                    <LoadingSpinner label="Loading Scores..." />
                }
                {
                    status === "loaded" && run &&
                    <>
                        <RunInfo run={run} />
                        <Link to={`/guesses/run/${run.id}${nightShow ? `?night=${nightShow.runNight}` : ''}`} className="text-brick-red my-10">View Guesses</Link>
                        <div className="row--center mx-bw-10">
                            {([...nightNumbers, null]).map((nightNumber, idx) => (
                                <div key={idx} className={`pointer night-item ${night == nightNumber ? 'night-item-selected' : ''}`} onClick={() => chooseNight(nightNumber)}>
                                    {nightNumber === null ? 'Total' : `Night ${nightNumber}`}
                                </div>
                            ))}
                        </div>
                        <LeaderboardInfo rankedUsers={rankedUsers} scoresByUser={scoresByUser} />
                    </>
                }
                {
                    status === "error" && <p>{error || "An unknown error occurred."}</p>
                }
            </div>
        </div>
    );
}

export default LeaderboardContainer;
