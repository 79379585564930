import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authenticationReducer } from '../../domain/Authentication/Authentication.store';
import { guessEditorReducer } from '../../domain/GuessEditor/GuessEditor.store';
import { guessListReducer } from '../../domain/GuessList/GuessList.store';
import { runListReducer } from '../../domain/RunList/RunList.store';
import { leaderboardReducer } from '../../domain/Leaderboard/Leaderboard.store';
import { profilePageReducer } from '../../domain/ProfilePage/ProfilePage.store';
import { showListReducer } from '../../domain/ShowList/ShowList.store';
import { adminReducer } from '../../domain/Admin/Admin.store';
import { avatarCreatorReducer } from '../../domain/AvatarCreator/AvatarCreator.store';
import { runGuessListReducer } from '../../domain/RunGuessList/RunGuessList.store';

export const apiRoot = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api' : 'https://api.phishingfun.com/api';

export enum ResponseStatus {
  Success = 200,
  UnknownError = 500,
  NotFound = 404,
  Unauthorized = 401,
  Conflict = 409,
}

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    authentication: authenticationReducer,
    avatarCreator: avatarCreatorReducer,
    guessEditor: guessEditorReducer,
    guessList: guessListReducer,
    showList: showListReducer,
    runList: runListReducer,
    runGuessList: runGuessListReducer,
    leaderboard: leaderboardReducer,
    profilePage: profilePageReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
