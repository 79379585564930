import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store/store";
import { Guess, GuessID } from "../../types/Guess.type";
import { Run } from "../../types/Run.type";
import { Show } from "../../types/Show.type";
import { Song } from "../../types/Song.type";

export interface GuessEditorState {
    guesses: Guess[],
    run: Run | null,
    show: Show | null,
    songList: Song[],
}

const initialState: GuessEditorState = {
    guesses: [],
    run: null,
    show: null,
    songList: [],
}

const saveGuessesReducer = (state: GuessEditorState, action: PayloadAction<Guess[]>) => ({
    ...state,
    guesses: action.payload || []
});

const storeRunReducer = (state: GuessEditorState, action: PayloadAction<Run>): GuessEditorState => ({
    ...state,
    run: action.payload || null
});

const storeShowReducer = (state: GuessEditorState, action: PayloadAction<Show>): GuessEditorState => ({
    ...state,
    show: action.payload || null
});

const storeSongListReducer = (state: GuessEditorState, action: PayloadAction<Song[]>): GuessEditorState => ({
    ...state,
    songList: action.payload || []
});

const addGuessReducer = (state: GuessEditorState, action: PayloadAction<Guess>): GuessEditorState => ({
    ...state,
    guesses: [...state.guesses, action.payload]
});

const removeGuessReducer = (state: GuessEditorState, action: PayloadAction<GuessID>): GuessEditorState => ({
    ...state,
    guesses: state.guesses.filter(guess => guess.id !== action.payload)
});

export const guessEditorSlice = createSlice({
    name: 'guessEditor',
    initialState,
    reducers: {
        saveGuesses: saveGuessesReducer,
        storeRun: storeRunReducer,
        storeShow: storeShowReducer,
        storeSongList: storeSongListReducer,
        addGuess: addGuessReducer,
        removeGuess: removeGuessReducer,
    }
})

export const { saveGuesses, storeRun, storeShow, storeSongList, addGuess, removeGuess } = guessEditorSlice.actions;

export const selectGuesses = (state: RootState): Guess[] => state.guessEditor.guesses;

export const selectRun = (state: RootState): Run | null => state.guessEditor.run;

export const selectShow = (state: RootState): Show | null => state.guessEditor.show;

export const selectSongList = (state: RootState): Song[] => state.guessEditor.songList;

export const guessEditorReducer = guessEditorSlice.reducer;