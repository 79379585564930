import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ResponseStatus } from "../../app/store/store";
import { Run } from "../../types/Run.type";
import { getAllRuns } from "../../api/Runs.api";
import { saveRuns, selectRuns } from "./RunList.store";
import { formatDateRange } from "../../shared/util/date.utils";
import "./RunList.scss";
import LoadingSpinner from "../../shared/icons/LoadingSpinner";
import { ReactComponent as LeftArrow } from "../../shared/icons/LeftArrow.svg";

const RunList: React.FC = () => {
    const navigate = useNavigate();
    const runs: Run[] = useSelector(selectRuns);
    const [ openRunId, setOpenRunId ] = useState<number | null>(null);
    const [ error, setError ] = useState<string | null>(null);
    const [ status, setStatus ] = useState<'loading' | 'loaded' | 'error'>('loading');
    const dispatch = useDispatch();

    useEffect(() => {
        getAllRuns().then(response => {
            if (response === ResponseStatus.UnknownError) {
                setError('An unknown error occurred. Please try again or refresh the page.');
                setStatus('error');
            } else {
                setStatus('loaded');
                dispatch(saveRuns(response));
            }
        })
    }, [ dispatch ]);


    return (
        <div className="column--centered">
            <div className="header-container">
                <p className="header">Runs</p>
                <div onClick={() => navigate(-1)}><LeftArrow /></div>
            </div>
            {
                status === 'loading' && <LoadingSpinner label="Loading Runs..."/>
            }
            { error && <p className="mx-20 text-center">{error}</p> }
            {
                status === 'loaded' && runs && 
                <div className="run-list">
                    {
                        runs.map((run, idx) => (
                            <div key={idx} className={`column--centered w-100 `}>
                                <div className="pointer" onClick={() => setOpenRunId(runId => runId === run.id? null : run.id)}> 
                                    <p className="my-15">{`${formatDateRange(run.dates)}: ${run.name}`}</p>
                                </div>
                                {
                                    openRunId === run.id &&
                                    <div className="row--space-around w-100 py-10">
                                        <Link to={`/guesses/${run.id}/edit`}>Edit Your Guesses</Link>
                                        <Link to={`/guesses/${run.id}`}>View All Guesses</Link>
                                        <Link to={`/scores/${run.id}`}>Leaderboard</Link>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default RunList;
