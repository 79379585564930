import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getGuessesForRun } from "../../api/Guess.api";
import { getRunById } from "../../api/Runs.api";
import { getShowsForRun } from "../../api/Shows.api";
import { getUserListByIds } from "../../api/User.api";
import { BackArrow } from "../../app/App.router";
import { ResponseStatus } from "../../app/store/store";
import { RunInfo } from "../../shared/component/RunInfo";
import LoadingSpinner from "../../shared/icons/LoadingSpinner";
import { organizeArrayByField } from "../../shared/util/utils";
import { Guess } from "../../types/Guess.type";
import { Run, RunID } from "../../types/Run.type";
import { Show } from "../../types/Show.type";
import { User, UserID } from "../../types/User.type";
import { checkUserCookie } from "../Authentication/Authentication.store";
import GuessList from "../GuessList/GuessList";
import { saveGuesses, saveNight, saveRun, saveShows, saveUsers, selectNightNumbers, selectNightShow, selectOrganizedGuessesByUser, selectRun, selectUsersOrganized } from "./RunGuessList.store";

const RunGuessListContainer: React.FC = () => {
    const { runId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const night = searchParams.get('night');
    const run: Run | null = useSelector(selectRun);
    const nightNumbers: number[] = useSelector(selectNightNumbers);
    const nightShow: Show | null = useSelector(selectNightShow);
    const organizedGuesses: Record<UserID, {"complete": Guess[], "incomplete": Guess[]}> = useSelector(selectOrganizedGuessesByUser);
    const organizedUsers: Record<UserID, User> = useSelector(selectUsersOrganized);
    const [ status, setStatus ] = useState<"loading" | "loaded" | "error">("loading");
    const [ error, setError ] = useState<string | null>(null);
    const dispatch = useDispatch();
    

    const collectGuessInfo = async (runId: RunID) => {
        const scores = await getGuessesForRun(runId);
        if (scores === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        const guessesByUser = organizeArrayByField(scores, "userId");
        const userIds = Object.keys(guessesByUser).map(user => parseInt(user));
        const users = await getUserListByIds(userIds);
        if (users === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        const run = await getRunById(runId);
        if (run === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        } else if (run === ResponseStatus.NotFound) {
            setError("Run not found");
            setStatus("error");
            return;
        }
        const shows = await getShowsForRun(runId);
        if (shows === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        dispatch(saveGuesses(scores));
        dispatch(saveUsers(users));
        dispatch(saveRun(run));
        dispatch(saveShows(shows));
        setStatus("loaded");
    }

    useEffect(() => {
        if (!runId || isNaN(parseInt(runId))) {
            setError("Run not found");
        } else {
            dispatch(checkUserCookie());
            collectGuessInfo(parseInt(runId));
        }

    }, [ runId, dispatch ]);

    useEffect(() => {
        if (night && !isNaN(parseInt(night))) {
            dispatch(saveNight(parseInt(night)));
        }
    }, [ night, dispatch ]);

    const chooseNight = (night: number | null) => {
        if (night !== null) {
            setSearchParams({night: night.toString()});
        } else {
            setSearchParams();
        }
        dispatch(saveNight(night));
    }

    return (
        <div className="column--centered">
            <div className="header-container">
                <p className="header">Guesses</p>
                <BackArrow />
            </div>
            { status === 'loading' && <LoadingSpinner label="Loading Guesses..." /> }
            { status === "error" && <p>{error || "An unknown error occurred."}</p> }
            {
                status === "loaded" && run &&
                <>
                    <RunInfo run={run} />
                    <Link to={`/scores/${run.id}${nightShow ? `?night=${nightShow.runNight}` : ''}`} className="text-brick-red my-10">View Leaderboard</Link>
                    <div className="row--center mx-bw-10">
                        {([...nightNumbers, null]).map((nightNumber, idx) => (
                            <div key={idx} className={`pointer night-item ${night == nightNumber ? 'night-item-selected' : ''}`} onClick={() => chooseNight(nightNumber)}>
                                {nightNumber === null ? 'Total' : `Night ${nightNumber}`}
                            </div>
                        ))}
                    </div>
                    <div className="w-100 my-5">
                        <GuessList organizedGuesses={organizedGuesses} users={organizedUsers} />
                    </div>
                </>
            }
        </div>
    )
}

export default RunGuessListContainer;
