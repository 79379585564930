import { apiRoot, ResponseStatus } from "../app/store/store";
import { Guess, GuessID } from "../types/Guess.type";
import { RunID } from "../types/Run.type";
import { ShowID } from "../types/Show.type";
import { User, UserID } from "../types/User.type";

export const getScoresForShow = (showId: ShowID): Promise<Guess[] | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/guesses?showId=${showId}&completed=true`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('guesses')) {
                resolve(data.guesses as Guess[]);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    });
}

export const getScoresForRun = (runId: RunID): Promise<Guess[] | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/guesses?runId=${runId}&completed=true`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('guesses')) {
                resolve(data.guesses as Guess[]);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    });
}

export const getScoresForUserForRun = (userId: UserID, runId: RunID): Promise<Guess[] | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/guesses?runId=${runId}&userId=${userId}&completed=true`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('guesses')) {
                resolve(data.guesses as Guess[]);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    });
}

export const getScoresForUser = (userId: UserID): Promise<Guess[] | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/guesses?userId=${userId}&completed=true`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('guesses')) {
                resolve(data.guesses as Guess[]);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    });
}

export const updateScore = (userId: UserID, guessId: GuessID, completed: boolean, points: number): Promise<ResponseStatus.Success | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/guesses/${guessId}`, { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, completed, points })
        });
        if (response.status === 200) {
            resolve(ResponseStatus.Success);
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}