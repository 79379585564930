import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Selector } from "react-redux";
import { RootState } from "../../app/store/store";
import { arrayToDictByID, guessSorter, organizeArrayByField } from "../../shared/util/utils";
import { Guess } from "../../types/Guess.type";
import { Run } from "../../types/Run.type";
import { Show } from "../../types/Show.type";
import { User, UserID } from "../../types/User.type";

export interface RunGuessListState {
    guesses: Guess[],
    users: User[],
    run: Run | null,
    shows: Show[],
    night: number | null,
}

const initialState: RunGuessListState = {
    guesses: [],
    users: [],
    run: null,
    shows: [],
    night: null,
}

const saveGuessesReducer = (state: RunGuessListState, action: PayloadAction<Guess[]>): RunGuessListState => ({
    ...state,
    guesses: action.payload
});

const saveUsersReducer = (state: RunGuessListState, action: PayloadAction<User[]>): RunGuessListState => ({
    ...state,
    users: action.payload || []
});

const saveRunReducer = (state: RunGuessListState, action: PayloadAction<Run>): RunGuessListState => ({
    ...state,
    run: action.payload || null
});

const saveShowsReducer = (state: RunGuessListState, action: PayloadAction<Show[]>): RunGuessListState => ({
    ...state,
    shows: action.payload || []
});

const saveNightReducer = (state: RunGuessListState, action: PayloadAction<number | null>): RunGuessListState => {
    return ({
        ...state,
        night: action.payload
    })
};

export const runGuessListSlice = createSlice({
    name: 'scores',
    initialState,
    reducers: {
        saveGuesses: saveGuessesReducer,
        saveUsers: saveUsersReducer,
        saveRun: saveRunReducer,
        saveShows: saveShowsReducer,
        saveNight: saveNightReducer,
    }
})

export const { saveGuesses, saveUsers, saveRun, saveShows, saveNight } = runGuessListSlice.actions;


export const selectShows: Selector<RootState, Show[]> = state => state.runGuessList.shows;

export const selectNightNumbers: Selector<RootState, number[]> = state => state.runGuessList.shows.map(show => show.runNight).sort();

export const selectNight: Selector<RootState, number | null> = state => state.runGuessList.night;

export const selectNightShow: Selector<RootState, Show | null> = state => state.runGuessList.shows.find(show => show.runNight === state.leaderboard.night) || null;

export const selectAllGuesses: Selector<RootState, Guess[]> = (state) => state.runGuessList.guesses;
export const selectGuessesByUser: Selector<RootState, Record<UserID, Guess[]>> = createSelector(selectAllGuesses, selectShows, selectNight, (scores, shows, night) => {
    const show = night !== null ? shows.find(s => s.runNight === night) : undefined;
    if (show) {
        return organizeArrayByField(scores.filter(s => s.showId === show.id), "userId");
    } else {
        return organizeArrayByField(scores, "userId");
    }
});
export const selectOrganizedGuessesByUser: Selector<RootState, Record<UserID, {"complete": Guess[], "incomplete": Guess[]}>> = createSelector(selectGuessesByUser, (guessesDict) => {
    let orderedGuessesDict: Record<UserID, {"complete": Guess[], "incomplete": Guess[]}> = {};
    Object.keys(guessesDict).forEach(userId => {
        orderedGuessesDict[parseInt(userId)] = {"complete": [], "incomplete": []};
        let guesses = [ ...guessesDict[parseInt(userId)] ];
        guesses.sort(guessSorter);
        const pivot = guesses.findIndex(guess => !guess.completed);
        // no pivot -> all completed
        if (pivot >= 0) {
            orderedGuessesDict[parseInt(userId)]["complete"] = guesses.slice(0, pivot);
            orderedGuessesDict[parseInt(userId)]["incomplete"] = guesses.slice(pivot);
        } else {
            orderedGuessesDict[parseInt(userId)]["complete"] = guesses;
        }
        
    })
    return orderedGuessesDict;
});

export const selectUsers: Selector<RootState, User[]> = state => state.runGuessList.users;
export const selectUsersOrganized: Selector<RootState, Record<UserID, User>> = createSelector(selectUsers, (users) => arrayToDictByID(users));

//export const selectRankedUsers: Selector<RootState, Record<UserID, number>> = createSelector(selectAllScores, (scores) => rankUsers(scores));

export const selectRun: Selector<RootState, Run | null> = state => state.runGuessList.run;


export const runGuessListReducer = runGuessListSlice.reducer;
