import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatDateRange } from "../../shared/util/date.utils";
import { toTitleCase } from "../../shared/util/utils";
import { ShowGroupRun, ShowGroupVenue, ShowGroupYear } from "../../types/Show.type";
import { FilterType } from "./ShowListContainer";
import "./ShowList.scss";

interface ShowListProps {
    filterType: FilterType;
    showsByRun: ShowGroupRun[];
    showsByYear: ShowGroupYear[];
    showsByVenue: ShowGroupVenue[];
}

const ShowList: React.FC<ShowListProps> = ({ filterType, showsByRun, showsByYear, showsByVenue }) => {
    const [ openRunId, setOpenRunId ] = useState<string | number | null>(null);
    const [ openShowId, setOpenShowId ] = useState<number | null>(null);

    const RunGroupItem = ({ showGroup }: { showGroup: ShowGroupRun}) => (
        <div className={`column--centered w-100 ${openRunId === showGroup.runId ? 'bg-brick-red-15' : ''}`}>
            <div className="pointer" onClick={() => {setOpenRunId(runId => runId === showGroup.runId ? null : showGroup.runId); setOpenShowId(null);}}> 
                <p className={`my-15 ${openRunId === showGroup.runId ? 'font-medium text-brick-red' : ''}`}>{`${formatDateRange(showGroup.runDates)} : ${showGroup.runName}`}</p>
            </div>
            {
                openRunId === showGroup.runId &&
                <div className="row--center w-100 border-box mx-bw-5vw py-10 ">
                    {
                        showGroup.shows.map((show, idx) => (
                            <div key={'rungroup'+idx} className={`show-item pointer pb-5 ${openShowId === show.id ? 'border-bottom border-brick-red' : ''}`}
                                onClick={() => setOpenShowId(showId => showId === show.id ? null : show.id)}
                                >
                                <p className="m-0">Night {show.runNight}</p>
                            </div>
                        ))
                    }
                </div>
            }
            {
                openRunId === showGroup.runId && openShowId && 
                <div className="link-container w-100">
                    <Link to={`/guesses/run/${openRunId}?night=${showGroup.shows.find(s => s.id === openShowId)?.runNight}`}>View All Guesses</Link>
                    {/*<Link to={`/guesses/${openShowId}`}>View All Guesses</Link>*/}
                    <Link to={`/guesses/${openShowId}/edit`}>Edit Your Guesses</Link>
                    <Link to={`/scores/${openRunId}?night=${showGroup.shows.find(s => s.id === openShowId)?.runNight}`}>Leaderboard</Link>
                </div>
            }
        </div>
    );

    const YearGroupItem = ({ showGroup }: { showGroup: ShowGroupYear}) => (
        <div className={`column--centered w-100 ${openRunId === showGroup.year ? 'bg-brick-red-15' : ''}`}>
            <div className="pointer" onClick={() => {setOpenRunId(runId => runId === showGroup.year ? null : showGroup.year); setOpenShowId(null);}}> 
                <p className={`my-15 ${openRunId === showGroup.year ? 'font-medium text-brick-red' : ''}`}>{showGroup.year}</p>
            </div>
            {
                openRunId === showGroup.year &&
                <div className="column--centered  border-box my-bw-10 py-10 ">
                    {
                        showGroup.shows.map((show, idx) => (
                            <>
                                <div key={'showgroup'+idx} className={`show-item pointer pb-5  ${openShowId === show.id ? 'border-bottom border-brick-red' : ''}`}
                                    onClick={() => setOpenShowId(showId => showId === show.id ? null : show.id)}
                                    >
                                    <p className="m-0 text-center">{show.date} - {show.venue}</p>    
                                </div>
                                {
                                    openRunId === showGroup.year && openShowId === show.id && 
                                    <div className="link-container w-100vw" key={`${idx}show`}>
                                        <Link to={`/guesses/run/${openRunId}?night=${showGroup.shows.find(s => s.id === openShowId)?.runNight}`}>View All Guesses</Link>
                                        {/*<Link to={`/guesses/${openShowId}`}>View All Guesses</Link>*/}
                                        <Link to={`/guesses/${openShowId}/edit`}>Edit Your Guesses</Link>
                                        <Link to={`/scores/${openRunId}?night=${showGroup.shows.find(s => s.id === openShowId)?.runNight}`}>Leaderboard</Link>
                                    </div>
                                }
                            </>
                        ))
                    }
                </div>
            }
        </div>
    );

    const VenueGroupItem = ({ showGroup }: { showGroup: ShowGroupVenue}) => (
        <div className={`column--centered w-100 ${openRunId === showGroup.venue ? 'bg-brick-red-15' : ''}`}>
            <div className="pointer" onClick={() => {setOpenRunId(runId => runId === showGroup.venue ? null : showGroup.venue); setOpenShowId(null);}}> 
                <p className={`my-15 ${openRunId === showGroup.venue ? 'font-medium text-brick-red' : ''}`}>{toTitleCase(showGroup.venue)}</p>
            </div>
            {
                openRunId === showGroup.venue &&
                <div className="column--centered w-100 border-box my-bw-10 py-10 ">
                    {
                        showGroup.shows.map((show, idx) => (
                            <>
                                <div key={'venuegroup'+idx} className={`show-item pointer pb-5  ${openShowId === show.id ? 'border-bottom border-brick-red' : ''}`}
                                    onClick={() => setOpenShowId(showId => showId === show.id ? null : show.id)}
                                    >
                                    <p className="m-0">{show.date}</p>    
                                </div>
                                {
                                    openRunId === showGroup.venue && openShowId === show.id && 
                                    <div className="link-container w-100" key={`${idx}show`}>
                                        <Link to={`/guesses/run/${openRunId}?night=${showGroup.shows.find(s => s.id === openShowId)?.runNight}`}>View All Guesses</Link>
                                        {/*<Link to={`/guesses/${openShowId}`}>View All Guesses</Link>*/}
                                        <Link to={`/guesses/${openShowId}/edit`}>Edit Your Guesses</Link>
                                        <Link to={`/scores/${openRunId}?night=${showGroup.shows.find(s => s.id === openShowId)?.runNight}`}>Leaderboard</Link>
                                    </div>
                                }
                            </>
                        ))
                    }
                </div>
            }
        </div>
    );

    return (
        <div className="run-list w-100">
            {
                filterType === 'run' &&  showsByRun.map((showGroup, idx) => <RunGroupItem showGroup={showGroup} key={idx} />)
            }
            {
                filterType === 'year' &&  showsByYear.map((showGroup, idx) => <YearGroupItem showGroup={showGroup} key={idx} />)
            }
            {
                filterType === 'venue' &&  showsByVenue.map((showGroup, idx) => <VenueGroupItem showGroup={showGroup} key={idx} />)
            }
        </div>
    )
}

export default ShowList;
