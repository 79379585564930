import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { checkUserCookie, selectUserId } from "../../domain/Authentication/Authentication.store";
import HomeLogo from "../../shared/icons/Home.svg";
import { UserID } from "../../types/User.type";
import "./Home.scss";

const Home: React.FC = () => {
    const dispatch = useDispatch();
    const userId: UserID | null = useSelector(selectUserId);

    useEffect(() => {
        dispatch(checkUserCookie());
    }, []);

    return (
        <div className="column--centered">
            <div className="home-container" />
            <img src={HomeLogo} alt="" className="home-bg" />
            <div className="h-20vh" />
            {/*<Link to={loggedIn ? "/runs" : "/login"}>{ loggedIn ? 'Runs' : 'Login' }</Link>*/}
            <div className="home-button">
                <Link to={userId !== null ? "/shows" : "/login"}>{ userId !== null ? 'Shows' : 'Login' }</Link>
            </div>
        </div>
    );
}

export default Home;
