import React, { useState } from "react";
import { Song, SongID } from "../../types/Song.type";
import { stringSimilarity } from "../../shared/util/utils";
import { ReactComponent as CloseIcon } from "../../shared/icons/CloseIcon.svg";

interface SongSearchProps {
    songList: Song[];
    selectSong: (songId: SongID) => void;
    selectedSong: string | null;
}

const SongSearch: React.FC<SongSearchProps> = ({ songList, selectSong, selectedSong }) => {
    const [ songInput, setSongInput ] = useState('');
    const [ songResults, setSongResults ] = useState<Song[]>([]);
    const allSongs = [...songList];

    const searchSong = (query: string) => {
        if (query === "") {
            setSongResults([]);
            return;
        }
        let songs = allSongs.filter(song => song.name.toLowerCase().includes(query.toLowerCase()));
        //let songs = allSongs.filter(song => song.name.toLowerCase().startsWith(query.toLowerCase()));
        songs = songs.sort((song1, song2) => stringSimilarity(song2.name, query) - stringSimilarity(song1.name, query));

        const topSongs = songs.slice(0, 3);
        setSongResults(topSongs);
    }

    const clear = () => {
        setSongInput("");
        setSongResults([]);
        if (selectedSong) selectSong(selectedSong);
    }

    return (
        <div className="song-search-container">
            <div className="search-bar">
                <p className="m-0">Search: </p>
                <input type="text" value={songInput} onChange={e => {setSongInput(e.target.value); searchSong(e.target.value)}} placeholder="Song title" />
                <CloseIcon className="pointer fill-brick-red" onClick={clear} />
            </div>
            {
                songResults.length > 0 &&
                <div className="search-results">
                    {
                        songResults.map(song => (
                            <div key={song.id} onClick={() => selectSong(song.id)} className={`result-item ${selectedSong === song.id ? 'selected' : ''}`}>
                                <p className="m-0">{song.name}</p>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    );
};

export default SongSearch;
