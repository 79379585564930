import React, { useState } from "react";
import { suggestSong } from "../../api/Song.api";
import { ResponseStatus } from "../../app/store/store";
import LoadingSpinner from "../icons/LoadingSpinner";

interface SongSuggestModalProps {
    close: () => void;
}

const SongSuggestModal: React.FC<SongSuggestModalProps> = ({ close }) => {
    const [ songInput, setSongInput ] = useState<string | null>(null);
    const [ status, setStatus ] = useState<'loading' | 'loaded' | 'error' | 'success'>('loaded');
    const [ error, setError ] = useState<string | null>(null);

    const submitSuggestion = async () => {
        if (!songInput || songInput === "") return;
        setStatus('loading');
        const result = await suggestSong(songInput);
        if (result === ResponseStatus.Success) {
            setStatus('success');
        } else {
            setError("An unknown error occurred.");
            setStatus('error');
        }
    }

    return (
        <div className="column--centered w-100">
            <div className="w-100 row--end">
                <i onClick={close} className="bi bi-x-lg pointer" />
            </div>
            <p className="header m-0 text-center">Suggest a Song</p>
            { status === 'loading' && <LoadingSpinner /> }
            { status === 'error' && <p>{error || "An unknown error occurred."}</p> }
            { status === 'success' && <p>Successfully suggested Song!</p> }
            <p className="text-center px-10">If you think we're missing a song, we very likely could be! We currently have to manually upload the list of available songs, which means there are covers and infrequent songs that get forgotten. Please send us your suggestion so it can be added!</p>
            <div className="row align-center my-10 w-75">
                <p className="m-0 mr-10 w-20vw">Song Title: </p>
                <input className="flex-grow rounded bg-brick-red-10 border border-brick-red p-5-10" type="text" value={songInput || ''} onChange={e => setSongInput(e.target.value)} placeholder="Song Name" />
            </div>
            <button onClick={submitSuggestion} disabled={status === "loading"} className="auth-button" >
                <p className="m-0 ">Submit</p>
            </button>
        </div>
    )
}

export default SongSuggestModal;
