import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Selector } from "react-redux";
import { RootState } from "../../app/store/store";
import { arrayToDictByID, organizeArrayByField, organizeScoresByUserId, rankScores, rankScoresObj, rankUsers } from "../../shared/util/utils";
import { Guess } from "../../types/Guess.type";
import { Run } from "../../types/Run.type";
import { Show } from "../../types/Show.type";
import { User, UserID } from "../../types/User.type";

export interface LeaderboardState {
    scores: Guess[],
    users: User[],
    run: Run | null,
    shows: Show[],
    night: number | null,
}

const initialState: LeaderboardState = {
    scores: [],
    users: [],
    run: null,
    shows: [],
    night: null,
}

const saveScoresReducer = (state: LeaderboardState, action: PayloadAction<Guess[]>): LeaderboardState => ({
    ...state,
    scores: action.payload
});

const saveUsersReducer = (state: LeaderboardState, action: PayloadAction<User[]>): LeaderboardState => ({
    ...state,
    users: action.payload || []
});

const saveRunReducer = (state: LeaderboardState, action: PayloadAction<Run>): LeaderboardState => ({
    ...state,
    run: action.payload || null
});

const saveShowsReducer = (state: LeaderboardState, action: PayloadAction<Show[]>): LeaderboardState => ({
    ...state,
    shows: action.payload || []
});

const saveNightReducer = (state: LeaderboardState, action: PayloadAction<number | null>): LeaderboardState => {
    return ({
        ...state,
        night: action.payload
    })
};

export const leaderboardSlice = createSlice({
    name: 'scores',
    initialState,
    reducers: {
        saveScores: saveScoresReducer,
        saveUsers: saveUsersReducer,
        saveRun: saveRunReducer,
        saveShows: saveShowsReducer,
        saveNight: saveNightReducer,
    }
})

export const { saveScores, saveUsers, saveRun, saveShows, saveNight } = leaderboardSlice.actions;


export const selectShows: Selector<RootState, Show[]> = state => state.leaderboard.shows;

export const selectNightNumbers: Selector<RootState, number[]> = state => state.leaderboard.shows.map(show => show.runNight).sort();

export const selectNight: Selector<RootState, number | null> = state => state.leaderboard.night;

export const selectNightShow: Selector<RootState, Show | null> = state => state.leaderboard.shows.find(show => show.runNight === state.leaderboard.night) || null;

export const selectAllScores: Selector<RootState, Guess[]> = (state) => state.leaderboard.scores;
export const selectScoresByUser: Selector<RootState, Record<UserID, Guess[]>> = createSelector(selectAllScores, selectShows, selectNight, (scores, shows, night) => {
    const show = night !== null ? shows.find(s => s.runNight === night) : undefined;
    if (show) {
        return organizeArrayByField(scores.filter(s => s.showId === show.id), "userId");
    } else {
        return organizeArrayByField(scores, "userId");
    }
});

export const selectUsers: Selector<RootState, User[]> = state => state.leaderboard.users;
export const selectUsersDict: Selector<RootState, Record<string, User>> = state => arrayToDictByID(state.leaderboard.users);

export const selectRankedUsers: Selector<RootState, ({userId: UserID, points: number})[]> = createSelector(selectAllScores, (scores) => rankScores(scores));
export const selectRankedUsersObj: Selector<RootState, ({user: User, points: number})[]> = createSelector(selectAllScores, selectUsers, selectShows, selectNight, (scores, users, shows, night) => {
    const show = night !== null ? shows.find(s => s.runNight === night) : undefined;
    if (show) {
        return rankScoresObj(scores.filter(s => s.showId === show.id), users);
    } else {
        return rankScoresObj(scores, users);
    }
});
//export const selectRankedUsers: Selector<RootState, Record<UserID, number>> = createSelector(selectAllScores, (scores) => rankUsers(scores));

export const selectRun: Selector<RootState, Run | null> = state => state.leaderboard.run;


export const leaderboardReducer = leaderboardSlice.reducer;
