import { apiRoot, ResponseStatus } from "../app/store/store";
import { Song } from "../types/Song.type";

export const getAllSongs = (): Promise<Song[] | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/songs`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('songs')) {
                resolve(data.songs as Song[]);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    });
}

export const suggestSong = (song: string): Promise<ResponseStatus.Success | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/songs/suggest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({ song })
        });
        resolve(response.status === 200 ? ResponseStatus.Success : ResponseStatus.UnknownError);
    })
}
