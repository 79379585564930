import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { DropDownUpIcon, DropDownIcon } from "../../shared/icons/Dropdown";
import { Guess } from "../../types/Guess.type";
import { Show } from "../../types/Show.type";
import { User, UserID } from "../../types/User.type";
import { selectUserId } from "../Authentication/Authentication.store";
import { AvatarIcon } from "../AvatarCreator/AvatarIcon";
import { selectNightShow } from "../RunGuessList/RunGuessList.store";

interface GuessListProps {
    organizedGuesses: Record<UserID, {"complete": Guess[], "incomplete": Guess[]}>,
    users: Record<UserID, User>,
}

function zip<A, B>(a: A[], b: B[]): [A, B][] {return a.map((k, i) => [k, b[i]])};

//const resolveGuesses = (organizedGuesses: Record<UserID, {"complete": Guess[], "incomplete": Guess[]}>, users: Record<UserID, User>): {user: User, guesses: {"complete": Guess[], "incomplete": Guess[]}}[];

const GuessList: React.FC<GuessListProps> = ({ organizedGuesses, users }) => {
    //const resolvesGuesses: {user: User, guesses: {"complete": Guess[], "incomplete": Guess[]}}[] = zip(users, organizedGuesses);
    const nightShow: Show | null = useSelector(selectNightShow);
    const currentUserId: UserID | null = useSelector(selectUserId);
    const [ openUserIds, setOpenUserIds ] = useState<UserID[]>([]);
    const toggleUser = (userId: UserID) => setOpenUserIds(ids => ids.includes(userId) ? ids.filter(id => id !== userId) : [ ...ids, userId ]);
    
    if (nightShow && Object.keys(organizedGuesses).length === 0) {
        return (
            <div className="column--centered">
                <p className="">There are no guesses for this show yet!</p>
                <Link to={`/guesses/${nightShow.id}/edit`} className="link">Add Guesses</Link>
            </div>
        )
    }

    return (
        <div className="column--centered w-100 max-w-500 mx-auto mx-auto px-20 border-box" id="guess-list">
            { 
                currentUserId && !(Object.keys(organizedGuesses).includes(currentUserId.toString())) && nightShow && 
                <Link to={`/guesses/${nightShow.id}/edit`} className="link mt-10">Add Your Guesses</Link>
            }
            { Object.entries(organizedGuesses).map(([ id, guesses ]) => {
                const userId: UserID = parseInt(String(id));
                const user: User = users[userId];

                return (
                    <div key={userId} className="w-100">
                        <div className="row--space-between align-center px-10" id="guess-user-header" onClick={() => toggleUser(userId)}>
                            <div className="row mx-bw-5 align-center">
                                { user.avatar && <AvatarIcon {...user.avatar} /> }
                                <Link className="ml-5" to={`/users/${userId}`}><p>{user.username || userId}</p></Link>
                                <p>{`- ${guesses.complete.length}/${guesses.complete.length + guesses.incomplete.length} completed`}</p>
                                {
                                    currentUserId && currentUserId === userId && nightShow && 
                                    <Link to={`/guesses/${nightShow.id}/edit`} className="link">edit</Link>
                                }
                            </div>
                            <div className="flex-grow row--end pointer">
                                { openUserIds.includes(userId) ? <DropDownUpIcon  /> : <DropDownIcon /> }                            
                            </div>
                        </div>
                        {
                            openUserIds.includes(userId) &&
                            <div className="px-20" id="user-guesses">
                                <div className="column my-bw-10">
                                    { guesses.incomplete.map(guess => (
                                        <div key={guess.id}>
                                            <a href={`https://www.phish.net/song/${guess.songId}`} target="_blank">
                                                <p className="m-0">{guess.songName}{guess.encore ? " (e)" : ""}</p>
                                            </a>
                                        </div>
                                    )) }
                                </div>
                                { guesses.complete.length > 0 &&  <div className="divider" />}
                                <div className="column my-bw-10">
                                    { guesses.complete.map(guess => (
                                        <div key={guess.id}>
                                            <a href={`https://www.phish.net/song/${guess.songId}`} target="_blank">
                                                <p className="text-crossed text-50pct m-0">{guess.songName}{guess.encore ? " (e)" : ""}</p>
                                            </a>
                                        </div>
                                    )) }
                                </div>
                            </div>
                        }
                    </div> 
                )
            }) }
        </div>
    );
}

export default GuessList;
