import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User, UserID } from "../../types/User.type";
import { attemptLogin, LoginResponse } from "../../api/Authentication.api";
import { checkUserCookie, logIn, selectUserId } from "./Authentication.store";
import { ResponseStatus } from "../../app/store/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./Auth.scss";
import { getUserByEmail, sendRecoveryCode } from "../../api/User.api";
import { Link } from "react-router-dom";
import { generateCode } from "../../shared/util/utils";

const ForgotPassword: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ emailInput, setEmailInput ] = useState<string | null>(null);
    const [ recoveryCode, setRecoveryCode ] = useState<string | null>(null);
    const [ recoveryInput, setRecoveryInput ] = useState<string | null>(null);
    const [ recoverySuccess, setRecoverySuccess ] = useState(false);
    const [ username, setUsername ] = useState<string | null>(null);
    const [ password, setPassword ] = useState<string | null>(null);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string | null>(null);

    const findUser = async (email: string | null ) => {
        if (email === null) {
            setError('Missing username.');
            return;
        }
        setLoading(true);
        const response = await getUserByEmail(email);
        if (response === ResponseStatus.NotFound) {
            setError(`No user found with email (${email}).`);
            return;
        } else if (response === ResponseStatus.UnknownError) {
            setError('An unknown error occurred. Please retry and/or refresh the page.');
            return;
        }
        const code = generateCode(4);
        const res = await sendRecoveryCode(email, code);
        if (res === ResponseStatus.Success) {
            setRecoveryCode(code);
            setUsername(response.username);
            setPassword(response.password);
            setLoading(false);
        } else {
            setError('An unknown error occurred when sending the recovery code. Please retry and/or refresh the page.');
            return;
        }
    }

    const verifyCode = async (recoveryInput: string | null) => {
        if (recoveryInput === null) {
            setError('Missing recovery code.');
            return;
        } else if (recoveryInput === recoveryCode) {
            setRecoverySuccess(true);
            return;
        } else {
            setError("Code does not match.");
            return;
        }
    }

    return (
        <div className="column--centered">
            <p className="header">Forgot Password</p>
            { error && <p className="text-center mx-10">{error}</p> }
            { 
                recoverySuccess ?
                <>
                    <p className="">Successfully recovered account! Below are your account details.</p>
                    <p className="">Username: {username}</p>
                    <p className="">Password: {password}</p>
                    <Link to="/login">Back to Login</Link>
                </> : 
                recoveryCode ?
                <>
                    <p className="">To obtain your user information, enter the recovery code sent to {emailInput}.</p>
                    <div className="row align-center my-10 w-75">
                        <p className="m-0 mr-10 w-20vw">Code: </p>
                        <input className="flex-grow rounded bg-brick-red-10 border border-brick-red p-5-10" type="text" value={recoveryInput || ''} onChange={e => setRecoveryInput(e.target.value)} placeholder="code" />
                    </div>
                    <button onClick={() => verifyCode(recoveryInput)} disabled={loading} className="auth-button" >
                        <p className="m-0 ">Verify Code</p>
                    </button>
                </> :
                <>
                    <div className="row align-center my-10 w-75">
                        <p className="m-0 mr-10 w-20vw">Email: </p>
                        <input className="flex-grow rounded bg-brick-red-10 border border-brick-red p-5-10" type="text" value={emailInput || ''} onChange={e => setEmailInput(e.target.value)} placeholder="email" />
                    </div>
                    <button onClick={() => findUser(emailInput)} disabled={loading} className="auth-button" >
                        <p className="m-0 ">Send Recovery Code</p>
                    </button>
                </>
            }
            {
                loading && <p>Loading...</p>
            }
        </div>
    );
}

export default ForgotPassword;
