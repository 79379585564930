import { apiRoot, ResponseStatus } from "../app/store/store";
import { Guess } from "../types/Guess.type";
import { RunID } from "../types/Run.type";
import { Show, ShowID } from "../types/Show.type";

export const getAllShows = (): Promise<Show[] | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/shows`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('shows')) {
                resolve(data.shows as Show[]);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}

export const getShowById = (showId: ShowID): Promise<Show | ResponseStatus.NotFound | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/shows/${showId}`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('show')) {
                resolve(data.show as Show);
            } else {
                resolve(ResponseStatus.NotFound);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}

export const getShowsForRun = (runId: RunID): Promise<Show[] | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/shows?runId=${runId}`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('shows')) {
                resolve(data.shows as Show[]);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}

export const getShowByRunNight = (runId: RunID, night: number): Promise<Show | ResponseStatus.NotFound | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/shows?runId=${runId}&night=${night}`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('show')) {
                resolve(data.show as Show);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else if (response.status === 404) {
            resolve(ResponseStatus.NotFound);
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}

export const getTodaysShow = (): Promise<Show | ResponseStatus.NotFound | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/shows/today`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('show')) {
                resolve(data.show as Show);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else if (response.status === 404) {
            resolve(ResponseStatus.NotFound);
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}