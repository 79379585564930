import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Selector } from "react-redux";
import { RootState } from "../../app/store/store";
import { compareDates, getYearFromDate } from "../../shared/util/date.utils";
import { DateString, Run, RunID } from "../../types/Run.type";
import { Show, ShowGroupRun, ShowGroupVenue, ShowGroupYear } from "../../types/Show.type";

export interface ShowListState {
    shows: Show[],
    runs: Run[]
}

const initialState: ShowListState = {
    shows: [],
    runs: []
}

const saveShowsReducer = (state: ShowListState, action: PayloadAction<Show[]>) => ({
    ...state,
    shows: action.payload || state.shows
});

const saveRunsReducer = (state: ShowListState, action: PayloadAction<Run[]>) => ({
    ...state,
    runs: action.payload || state.runs
});

export const showListSlice = createSlice({
    name: 'shows',
    initialState,
    reducers: {
        saveShows: saveShowsReducer,
        saveRuns: saveRunsReducer
    }
})

export const { saveShows, saveRuns } = showListSlice.actions;

export const selectShows: Selector<RootState, Show[]> = (state) => state.showList.shows;

export const selectRuns: Selector<RootState, Run[]> = (state) => state.showList.runs;

const organizeShowsByRun = (shows: Show[], runs: Run[]): ShowGroupRun[] => {
    let showsByRun: Record<RunID, Show[]> = {};
    shows.forEach(show => {
        if (!(show.runId in showsByRun)) {
            showsByRun[show.runId] = [];
        }
        showsByRun[show.runId].push(show);
    });
    let showGroups: ShowGroupRun[] = [];
    for (let [runId, shows] of Object.entries(showsByRun)) {
        const run = runs.find(r => r.id === parseInt(runId));
        if (!run) continue;
        showGroups.push({runId: parseInt(runId), runName: run.name, runDates: run.dates, shows});
    }
    showGroups.sort((group1, group2) => (new Date(group2.runDates[0]).getTime() - (new Date(group1.runDates[0])).getTime()))
    return showGroups;
}

const organizeShowsByYear = (shows: Show[]): ShowGroupYear[] => {
    let showsByYear: Record<number, Show[]> = {};
    shows.forEach(show => {
        const year = getYearFromDate(show.date);
        if (!(year in showsByYear)) {
            showsByYear[year] = [];
        }
        showsByYear[year].push(show);
    });
    return Object.entries(showsByYear).map(([year, shows]) => ({year: parseInt(year), shows: shows.sort((s1, s2) => (new Date(s2.date).getTime() - (new Date(s1.date)).getTime()))})).sort((a, b) => b.year - a.year);
}

const organizeShowsByVenue = (shows: Show[]): ShowGroupVenue[] => {
    let showsByVenue: Record<string, Show[]> = {};
    shows.forEach(show => {
        if (!(show.venue in showsByVenue)) {
            showsByVenue[show.venue] = [];
        }
        showsByVenue[show.venue].push(show);
    });
    let showGroups: ShowGroupVenue[] = Object.entries(showsByVenue).map(([venue, shows]) => ({venue, shows: shows.sort((s1, s2) => (new Date(s2.date).getTime() - (new Date(s1.date)).getTime()))}));
    showGroups.sort((group1, group2) => group1.venue.charCodeAt(0) - group2.venue.charCodeAt(0));
    return showGroups;
}

export const selectShowsByRun: Selector<RootState, ShowGroupRun[]> = (state) => organizeShowsByRun(state.showList.shows, state.showList.runs);
export const selectShowsByYear: Selector<RootState, ShowGroupYear[]> = (state) => organizeShowsByYear(state.showList.shows);
export const selectShowsByVenue: Selector<RootState, ShowGroupVenue[]> = (state) => organizeShowsByVenue(state.showList.shows);

export const showListReducer = showListSlice.reducer;