import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { Guess, GuessID } from "../../types/Guess.type";
import { Song, SongID } from "../../types/Song.type";
import { addGuess, removeGuess, selectGuesses, selectRun, selectShow, selectSongList } from "./GuessEditor.store";
import SongSearch from "./SongSearch";
import { ReactComponent as CloseIcon } from "../../shared/icons/CloseIcon.svg";
import { useParams } from "react-router";
import { UserID } from "../../types/User.type";
import { selectUserId } from "../Authentication/Authentication.store";
import { Run } from "../../types/Run.type";
import { ResponseStatus } from "../../app/store/store";
import { createGuess, deleteGuess, getGuessesForShow, getGuessesForUserForShow } from "../../api/Guess.api";
import "./GuessEditor.scss";
import SongSuggestModal from "../../shared/component/SongSuggestModal";
import { stringSimilarity } from "../../shared/util/utils";
import LoadingSpinner from "../../shared/icons/LoadingSpinner";
import { Show } from "../../types/Show.type";
import SongInput from "./SongInput";
import { getShowByRunNight } from "../../api/Shows.api";

Modal.setAppElement('#root');

interface GuessEditorProps {
    //allGuesses: Guess[];
    //addGuess: (songId: string, songName: string, encore: boolean) => Promise<boolean | "conflict">;
    //deleteGuess: (guessId: GuessID) => Promise<boolean>;
}

const formatGuesses = (allGuesses: Guess[]): (Guess | null)[] => {
    let guesses: Guess[] = allGuesses.filter(guess => !guess.completed);
    let nonEncore: Guess[] = [];
    let encore: Guess | null = null;
    guesses.forEach(guess => {
        if (guess.encore) {
            if (!encore) encore = guess;
        } else {
            if (nonEncore.length < 9) nonEncore.push(guess);
        }
    });
    let guessList: (Guess | null)[] = nonEncore;
    while (guessList.length < 9) {
        guessList.push(null);
    }
    guessList.push(encore);
    return guessList;
}

const GuessEditor: React.FC<GuessEditorProps> = () => {
    const { showId } = useParams();
    const userId: UserID | null = useSelector(selectUserId);
    const run: Run | null = useSelector(selectRun);
    const show: Show | null = useSelector(selectShow);
    const allGuesses: Guess[] = useSelector(selectGuesses);
    const guessList: (Guess | null)[] = formatGuesses(allGuesses);
    const songList: Song[] = useSelector(selectSongList);
    const [ selectedSong, setSelectedSong ] = useState<SongID | null>(null);
    const [ status, setStatus ] = useState<"loaded" | "loading" | "error">("loaded");
    const [ error, setError ] = useState<string | null>(null);
    const dispatch = useDispatch();
    const [ suggestModalOpen, setModalOpen ] = useState(false);
    const [ helpTextOpen, setHelpTextOpen ] = useState(true);
    const [ missingTextOpen, setMissingTextOpen ] = useState(true);

    const selectSong = (songId: SongID) => {
        setSelectedSong(currId => currId === songId ? null : songId);
    }

    const submitGuess = async (songId: SongID, encore: boolean) => {
        const song = songList.find(s => s.id === songId);
        if (!song || !userId || !run || !showId) return;
        setStatus("loading");
        const result = await createGuess(userId as UserID, run.id, parseInt(showId), songId, song.name, encore);
        if (result === ResponseStatus.Conflict) {
            setStatus("error");
            setError("You have already guesses this song for this show!");
        } else if (result === ResponseStatus.UnknownError) {
            setError("An unknown error occurred when adding this guess.");
            setStatus("error");
        } else {
            dispatch(addGuess(result));
            setStatus("loaded");
        }
        setSelectedSong(null);
    }

    const subtractGuess = async (guessId: GuessID | undefined) => {
        if (!guessId) return;
        setStatus("loading");
        const result = await deleteGuess(guessId);
        if (result === ResponseStatus.Success) {
            dispatch(removeGuess(guessId));
            setStatus("loaded");
        } else {
            setStatus("error");
        }
        setSelectedSong(null);
    }

    const addIncompleteGuesses = async (guesses: Guess[]) => {
        let availableSlots = guessList.filter(g => g === null).length;
        // if already full, can't add guesses
        if (availableSlots === 0) {
            setError("You have already made all of your guesses!");
            setStatus("error");
        }
        const incompleteGuesses = guesses.filter(guess => !guess.completed);
        console.log(incompleteGuesses);
        for (let guess of incompleteGuesses) {
            // done if no available spot
            if (availableSlots === 0) break;
            // skip if song already guessed
            if (guessList.some(g => g?.songId === guess.songId)) continue;
            // skip if encore and encore already used
            if (guess.encore && guessList[guessList.length - 1] !== null) continue;
            // add guess
            console.log(guess.songId);
            await submitGuess(guess.songId, guess.encore);
            availableSlots--;
        }
        setStatus("loaded");
    }

    const getPreviousGuesses = async () => {
        setStatus("loading");
        if (!show || !run || show.runNight === 0 || !userId) return;
        const prevShow = await getShowByRunNight(run.id, show.runNight-1);
        if (prevShow === ResponseStatus.NotFound) {
            setError("No show found for night.");
            setStatus("error");
            return;
        } else if (prevShow === ResponseStatus.UnknownError) {
            setStatus("error");
            return;
        }
        const guesses = await getGuessesForUserForShow(userId, prevShow.id);
        if (guesses === ResponseStatus.UnknownError) {
            setStatus("error");
            return;
        }
        addIncompleteGuesses(guesses);
    }

    return (
        <div id="guess-list-page">
            { 
                helpTextOpen &&
                <div className="row--center mx-bw-5 mt-10">
                    <p className="m-0 text-center text-black-50">(Click on an open slot to input your guess)</p>
                    <CloseIcon className="fill-black-50 pointer" style={{width: '16px'}} onClick={() => setHelpTextOpen(false)} />
                </div>
            }
            {
                missingTextOpen &&
                <div className="row--center mx-bw-5 mt-10">
                    <p className="text-center pointer m-0 text-black-50" onClick={() => setModalOpen(true)}>Missing a Song?</p>
                    <CloseIcon className="fill-black-50 pointer" style={{width: '16px'}} onClick={() => setMissingTextOpen(false)} />
                </div>
            }
            { 
                status === "loading" && 
                <div style={{position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', paddingTop: '30vh', backgroundColor: 'rgba(255, 255, 255, 0.75)'}}>
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '28px', fontWeight: 'bold'}}>
                        <LoadingSpinner label=" " />
                        <p className="text-center text-brick-red" >Loading...</p>
                    </div>
                </div> 
            }
            { 
                status === "error" && 
                <div className="row--center mx-bw-5">
                    <p className="text-center text-brick-red">{error || "An unknown error occurred."}</p> 
                    <p className="text-brick-red pointer" onClick={() => {setStatus("loaded"); setError(null)}}>x</p>
                </div>
            }
            <div className="guess-list px-20">
                {
                    guessList.map((guess, idx) => (
                        <div key={idx} className={`guess-item ${guess === null ? 'align-start' : ''}`}>
                            <p className="m-0">{idx < guessList.length - 1 ? `Song ${idx+1}` : 'Encore'}:</p>
                            {
                                guess !== null ?
                                <div className={`song-choice ${guess === null && selectedSong !== null ? 'song-choice-highlight' : ''}`} onClick={() => guess === null && selectedSong !== null ? submitGuess(selectedSong, idx === guessList.length - 1) : {}}>
                                    <p className="m-0 ">{guess?.songName}&nbsp;</p>
                                </div> :
                                <SongInput songList={songList} selectedSong={selectedSong} submitGuess={(song) => submitGuess(song, idx === guessList.length - 1)} />
                            }
                            <div style={guess === null ? {opacity:0.25, userSelect: 'none'} : {cursor: 'pointer'}} onClick={() => guess !== null ? subtractGuess(guess.id) : {}}>
                                <CloseIcon />
                            </div>
                        </div>
                    ))
                }
            </div>
            { <p onClick={getPreviousGuesses} className="text-center text-brick-red pointer">Copy Incomplete Guesses From Previous Night</p> }
            <Modal isOpen={suggestModalOpen} onRequestClose={() => setModalOpen(false)}
                contentLabel="Song Suggestion"
            >
                { show && show.runNight > 0 && <SongSuggestModal close={() => setModalOpen(false)} /> }
            </Modal>
        </div>
    )
}

export default GuessEditor;
