import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { scoreGuessesForShow } from "../../api/Guess.api";
import { getAllShows } from "../../api/Shows.api";
import { getAllSongs } from "../../api/Song.api";
import { getUserById } from "../../api/User.api";
import { ResponseStatus } from "../../app/store/store";
import LoadingSpinner from "../../shared/icons/LoadingSpinner";
import { Show, ShowID } from "../../types/Show.type";
import { Song } from "../../types/Song.type";
import { User } from "../../types/User.type";
import { selectUserId } from "../Authentication/Authentication.store";
import { selectAllSongs, selectShowId, selectShows, storeAllSongs, storeShowId, storeShows } from "./Admin.store";
import SetlistBuilder from "./SetlistBuilder";

const AdminContainer: React.FC = () => {
    const dispatch = useDispatch();
    const currentUserId = useSelector(selectUserId);
    const allSongs: Song[] = useSelector(selectAllSongs);
    const shows: Show[] = useSelector(selectShows);
    const showId: ShowID | null = useSelector(selectShowId);
    const selectedShow: Show | undefined = shows.find(s => s.id === showId);
    const [ currentUser, setCurrentUser ] = useState<User | null>(null);
    const [ error, setError ] = useState<string | null>(null);
    const [ status, setStatus ] = useState<'loading' | 'loaded' | 'error' | 'success'>('loading');

    const fetchInfo = async () => {
        const songList = await getAllSongs();
        const shows = await getAllShows();
        if (songList === ResponseStatus.UnknownError || shows === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        } else {
            dispatch(storeAllSongs(songList));
            dispatch(storeShows(shows));
            setStatus("loaded");
        }
    }

    const submitSongs = async (songs: {id: string, encore: boolean}[]) => {
        if (showId === null) return;
        setStatus("loading");
        const res = await scoreGuessesForShow(showId, songs);
        if (res === ResponseStatus.Success) {
            setStatus("success");
        } else {
            setError("An unknown error occurred while scoring guessing.");
            setStatus("error");
        }
    }

    useEffect(() => {
        if (currentUserId) {
            getUserById(currentUserId).then(user => {
                if (user !== ResponseStatus.UnknownError && user !== ResponseStatus.NotFound) {
                    setCurrentUser(user);
                    fetchInfo();
                }
            });
        } else {
            setCurrentUser(null);
        }
    }, [ currentUserId ]);

    if (currentUser === null) {
        return (
            <div className="column--centered">
                <p>You must be logged in to view this page.</p>
                <Link to="/login?redirect=admin" className="link">Login</Link>
            </div>
        )
    } else if (!currentUser.admin) {
        return (
            <div className="column--centered">
                <p>You must have admin priviliges to view this page. Sorry!</p>
                <p>If you believe you should have admin priviliges, please contact Max Pinheiro (610-425-7052)</p>
            </div>
        )
    }

    return (
        <div className="column--centered">
            <p className="text-center">Admin</p>
            {
                status === 'loading' &&
                <LoadingSpinner label="Loading..." />
            }
            {
                    status === 'success' &&
                    <p className="text-center">Successfully scored guesses.</p>
            }
            {
                    status === 'error' &&
                    <p className="text-center">{error || "An unknown error occurred."}</p>
            }
            {
                status === 'loaded' && !showId && shows &&
                <div>
                    <p>Select a Show:</p>
                    {
                        shows.map((show, idx) => (
                            <div key={idx} className="w-100 pointer" onClick={() => dispatch(storeShowId(showId !== show.id ? show.id : null))}>
                                <p className="text-center">{show.date}</p>
                            </div>
                        ))
                    }
                </div>
            }
            {
                status === 'loaded' && showId &&
                <>
                    <div className="row align-center">
                        <p className="">{selectedShow?.date} - {selectedShow?.venue}</p>
                    </div>
                    <div className="my-10" onClick={() => dispatch(storeShowId(null))}>Back</div>
                    <SetlistBuilder allSongs={allSongs} submit={submitSongs} />
                </>
            }
        </div>
    );
}

export default AdminContainer;