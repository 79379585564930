import React, { useState } from "react";
import { DropDownIcon, DropDownUpIcon } from "../../shared/icons/Dropdown";
import { toTitleCase } from "../../shared/util/utils";
import { Guess } from "../../types/Guess.type";
import { Run, RunID } from "../../types/Run.type";

interface RunRecordProps {
    scoreRecordOrganized: Record<RunID, Guess[]>,
    runRecordOrganized: Record<RunID, Run>,
}

const RunRecord: React.FC<RunRecordProps> = ({ scoreRecordOrganized, runRecordOrganized }) => {
    const [ runRecordOpen, setRunRecordOpen ] = useState(true);
    const [ openRunId, setOpenRunId ] = useState<RunID | null>(null);

    return (
        <div className="mx-10">
                <div className="row align-center mx-bw-10 pointer my-5" onClick={() => {}/*setRunRecordOpen(b => !b)*/}>
                    <p className="font-semibold m-0">Score Record:</p>
                    {/* runRecordOpen ? <DropDownUpIcon  /> : <DropDownIcon /> */}    
                </div>
                {
                    runRecordOpen &&
                    <div className="ml-10">
                        { Object.entries(scoreRecordOrganized).map(([runId, scores]) => (
                            <div key={runId} className="my-10">
                                <div onClick={() => setOpenRunId(id => id === parseInt(runId) ? null : parseInt(runId))} className="pointer row mx-bw-10 align-center"> 
                                    <p className="m-0">{runRecordOrganized[parseInt(runId)]?.name || ''} - <b className="text-brick-red">{`${scores.map(s => s.points).reduce((a, b) => a + b)} pts`}</b></p>
                                    { openRunId === parseInt(runId) ? <DropDownUpIcon  /> : <DropDownIcon /> }    
                                </div>
                                {
                                    openRunId === parseInt(runId) &&
                                    <div className="column--flex-start my-bw-5 py-10">
                                        {
                                            scores.map((score, idx) => (
                                                <div key={idx} className="">
                                                    <p className="m-0">{toTitleCase(score.songName.split('-').join(' '))} - {score.points} pts</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                        )) }
                    </div>  
                }
            </div>
    );
}

export default RunRecord;
