import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserById, updateAvatarForUser } from "../../api/User.api";
import { ResponseStatus } from "../../app/store/store";
import LoadingSpinner from "../../shared/icons/LoadingSpinner";
import { User, UserID } from "../../types/User.type";
import { selectUserId } from "../Authentication/Authentication.store";
import AvatarCreator from "./AvatarCreator";
import { AvatarConfig, saveUser, selectUser } from "./AvatarCreator.store";

const AvatarCreatorContainer: React.FC = () => {
    const currentUserId: UserID | null = useSelector(selectUserId);
    const [ status, setStatus ] = useState<'loading' | 'loaded' | 'error' | 'success'>('loading');
    const [ error, setError ] = useState<string | null>(null);
    const dispatch = useDispatch();
    const user: User | null = useSelector(selectUser);

    const collectUserInfo = async (userId: UserID) => {
        const user = await getUserById(userId);
        if (user === ResponseStatus.NotFound) {
            setError("User not found.");
            setStatus("error");
            return;
        } else if (user === ResponseStatus.UnknownError) {
            setError("An unknown error occurred.");
            setStatus("error");
            return;
        } else {
            dispatch(saveUser(user));
            setStatus("loaded");
            return;
        }
    }

    const saveAvatar = (avatar: AvatarConfig) => {
        if (!currentUserId) return;
        setStatus("loading");
        updateAvatarForUser(currentUserId, avatar).then(result => {
            if (result === ResponseStatus.Success) {
                setStatus("success");
            } else {
                setError("An unknown error occurred.");
                setStatus("error");
            }
        })
    }

    useEffect(() => {
        if (!currentUserId) {
            setError("You must be logged in to view this page.");
            setStatus("error");
            return;
        }
        collectUserInfo(currentUserId);
    }, [ currentUserId ]);

    return (
        <div className="column--centered">
            { status === 'loading' && <LoadingSpinner label="Loading Avatar..." /> }
            { 
                status === 'success' &&
                <div className="column--centered">
                    <p className="">Successfully updated avatar!</p>
                    <Link to={`/users/${currentUserId}`}>Back To Profile Page</Link>
                </div>
            }
            { 
                status === 'loaded' && user &&
                <AvatarCreator initHead={user.avatar.head} initTorso={user.avatar.torso} initBackground={user.avatar.background} save={saveAvatar} />
            }
        </div>
    )
}

export default AvatarCreatorContainer;
