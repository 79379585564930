import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DropDownUpIcon, DropDownIcon } from "../../shared/icons/Dropdown";
import { User, UserID } from "../../types/User.type";
import { AvatarIcon } from "../AvatarCreator/AvatarIcon";
import { defaultAvatar } from "../AvatarCreator/AvatarCreator.store";
import { Guess } from "../../types/Guess.type";

interface LeaderboardInfoProps {
    rankedUsers: {user: User, points: number}[],
    scoresByUser: Record<UserID, Guess[]>
}

const LeaderboardInfo: React.FC<LeaderboardInfoProps> = ({ rankedUsers, scoresByUser }) => {
    const [ openUserIds, setOpenUserIds ] = useState<UserID[]>([]);
    const toggleUser = (userId: UserID) => setOpenUserIds(ids => ids.includes(userId) ? ids.filter(id => id !== userId) : [ ...ids, userId ]);

    if (rankedUsers.length === 0) {
        return <p className="text-center">There are no scores for this show yet!</p>
    }

    return (
        <div className="column--centered w-100 max-w-500 mx-auto px-20 mb-20 border-box" id="leaderboard-list">
            { rankedUsers.map(({ user, points }, idx) => (
                <div key={user.id} className="w-100">
                    <div className="row--space-between align-center px-10" onClick={() => toggleUser(user.id)}>
                        <div className="row align-center mx-bw-5">
                            <p>{idx + 1}.</p>
                            { user.avatar ? <AvatarIcon {...user.avatar} /> : <AvatarIcon {...defaultAvatar} /> }
                            <Link className="ml-5" to={`/users/${user.id}`}><p>{user.username}</p></Link>
                        </div>
                        <div className="flex-grow row--end pointer align-center mx-bw-5">
                            <p className="m-0">{`${points} pts`}</p>
                            { openUserIds.includes(user.id) ? <DropDownUpIcon  /> : <DropDownIcon /> }                            
                        </div>
                    </div>
                    { openUserIds.includes(user.id) &&
                        <div className="px-20 my-bw-10">
                            {
                                scoresByUser[user.id] && scoresByUser[user.id].map(score => (
                                    <div key={score.id} className="row--space-between ">
                                        <p className="m-0">{score.songName} {score.encore && score.points > 1 ? ' (e)' : ''}</p>
                                        <div className="row align-center mx-bw-5">
                                            <p className="m-0">{`${score.points} pts`}</p>
                                            <div style={{"width": "10px"}} />
                                        </div>
                                        
                                    </div>
                                ))
                            }
                        </div>    
                    }
                    
                </div>
            )) }
        </div>
    );
}

export default LeaderboardInfo;
