import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toTitleCase } from "../../shared/util/utils";
import { Song, SongID } from "../../types/Song.type";
import SongSearch from "../GuessEditor/SongSearch";
import { addSong, selectSongList } from "./Admin.store";

interface SetlistBuilderProps {
    allSongs: Song[],
    submit: (songs: {id: string, encore: boolean}[]) => void,
}

const SetlistBuilder: React.FC<SetlistBuilderProps> = ({ allSongs, submit }) => {
    const songs: {id: string, encore: boolean}[] = useSelector(selectSongList);
    const [ selectedSong, setSelectedSong ] = useState<SongID | null>(null);
    const [ encore, setEncore ] = useState(false);
    const dispatch = useDispatch();

    const selectSong = (songId: SongID, encore: boolean) => {
        dispatch(addSong({id: songId, encore}));
        setSelectedSong(null);
    }

    return (
        <div className="column--centered">
            <SongSearch songList={allSongs} selectSong={(songId) => selectSong(songId, encore)} selectedSong={selectedSong} />
            <div className="row my-20 align-center">
                <p className="mr-5">Encore: </p>
                <input type="checkbox" checked={encore} onChange={() => setEncore(b => !b)} />
            </div>
            {
                selectedSong &&
                <div className="column--centered">
                    <p className="m-0">{toTitleCase(selectedSong.split('-').join(' '))}</p>
                    <div className="row--center mx-bw-10">
                        <p className="m-0">Encore? </p>
                        <p className="m-0 pointer" onClick={() => selectSong(selectedSong, false)}>No </p>
                        <p className="m-0 pointer" onClick={() => selectSong(selectedSong, true)}>Yes </p>
                    </div>
                </div>
            }
            {
                songs.map((song, idx) => (
                    <div key={idx} className="row--center mx-bw-10 py-5">
                        <p className="m-0">{`${idx+1}. `} {toTitleCase(song.id).split('-').join(' ')} {song.encore ? ' (e)' : ''}</p>
                    </div>
                ))
            }
            <button className="pointer border border-brick-red rounded px-10 py-5 my-10" onClick={() => submit(songs)}>Submit</button>
        </div>
    )
}

export default SetlistBuilder;
