import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User, UserID } from "../../types/User.type";
import { attemptLogin, LoginResponse } from "../../api/Authentication.api";
import { checkUserCookie, logIn, selectUserId } from "./Authentication.store";
import { ResponseStatus } from "../../app/store/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./Auth.scss";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const redirect = searchParams.get('redirect');
    const userId: UserID | null = useSelector(selectUserId);
    const [ username, setUsername ] = useState<string | null>(null);
    const [ password, setPassword ] = useState<string | null>(null);
    const [ showPassword, setShowPassword ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string | null>(null);

    const tryLogin = async (username: string | null, password: string | null) => {
        if (username === null) {
            setError('Missing username.');
        } else if (password === null) {
            setError('Missing password.');
        } else {
            setLoading(true);
            const response = await attemptLogin(username, password);
            if (response instanceof Object) {
                const user = response as User;
                dispatch(logIn(user.id));
                setLoading(false);
                window.localStorage.setItem('gp-user', JSON.stringify(user));
                navigate(redirect ? `/${redirect}` : '/shows');
            } else if (response === ResponseStatus.Unauthorized) {
                setError('Invalid password.');
            } else if (response === ResponseStatus.NotFound) {
                setError('Invalid username.');
            } else {
                setError('An unknown error occurred. Please retry and/or refresh the page.');
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        dispatch(checkUserCookie());
    }, [ dispatch ]);

    useEffect(() => {
        if (userId) {
            navigate(redirect ? `/${redirect}` : `/users/${userId}`);
        }
    }, [ userId ]);

    return (
        <div className="column--centered">
            <p className="header">Login</p>
            { error && <p className="text-center mx-10">{error}</p> }
            <div className="row align-center my-10 w-75">
                <p className="m-0 mr-10 w-20vw">Username: </p>
                <input className="flex-grow rounded bg-brick-red-10 border border-brick-red p-5-10" type="text" value={username || ''} onChange={e => setUsername(e.target.value)} placeholder="username" />
            </div>
            <div className="row align-center my-10 w-75">
                <p className="m-0 mr-10 w-20vw">Password: </p>
                <div className="flex-grow rounded bg-brick-red-10 border border-brick-red row--space-between align-center">
                    <input className="flex-grow rounded bg-brick-red-10  p-5-10 " type={showPassword ? "text" : "password"} value={password || ''} onChange={e => setPassword(e.target.value)} placeholder="password" />
                    <div onClick={() => setShowPassword(p => !p)} className="pointer mx-6"><i className={`bi ${showPassword ? 'bi-eye' : 'bi-eye-slash'}`} /></div>
                </div>
                
            </div>
            <Link to="/forgot-password" className="text-center pointer font-light">Forgot username/password?</Link>
            <button onClick={() => tryLogin(username, password)} disabled={loading} className="auth-button mt-10" >
                <p className="m-0 ">Login</p>
            </button>
            {
                loading && <p>Loading...</p>
            }
        </div>
    );
}

export default Login;
