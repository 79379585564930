import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getTodaysShow } from "../../api/Shows.api";
import { ResponseStatus } from "../../app/store/store";
import LoadingSpinner from "../../shared/icons/LoadingSpinner";
import { checkUserCookie } from "../Authentication/Authentication.store";
import { ReactComponent as Icon404 } from "../../shared/icons/404.svg";

const Today: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ status, setStatus ] = useState<'loading' | 'loaded' | 'error' | 'no-show'>('loading');
    const [ error, setError ] = useState<string | null>(null);

    const findTodaysShow = async () => {
        const result = await getTodaysShow();
        if (result === ResponseStatus.NotFound) {
            //setError("No show found for today.");
            setStatus('no-show');
        } else if (result === ResponseStatus.UnknownError) {
            setError("An unknown error occurred. Please refresh the page and try again.");
            setStatus('error');
        } else {
            setStatus('loaded');
            navigate(`/guesses/run/${result.runId}?night=${result.runNight}`);
        }
    };

    useEffect(() => {
        dispatch(checkUserCookie());
        findTodaysShow();
    }, []);

    return (
        <div className="column--centered py-20">
            { status === 'loading' && <LoadingSpinner label="Finding Today's Show..." /> }
            { status === 'loaded' && <p>Show found! Navigating to guesses page...</p> }
            { status === 'error' && <p>{error || "An unknown error occurred."}</p> }
            {
                status === 'no-show' &&
                <div className="column--centered my-bw-20 py-20">
                    <Icon404 className="mt-10"/>
                    <p>Sorry! There is no show today.</p>
                    <Link to="/shows">View All Shows</Link>
                    <a href="https://phish.com/tours/" target="_blank">View Upcoming Tours</a>
                </div>
            }
        </div>
    );
}

export default Today;
