import React from "react";
import { Run } from "../../types/Run.type";
import { Show } from "../../types/Show.type";
import { dateToString, formatDateRange } from "../util/date.utils";

interface RunInfoProps {
    run: Run
}

export const RunInfo: React.FC<RunInfoProps> = ({run}) => (
    <div className="column--centered" id="run-info">
        <p className="mt-0 mb-10 text-125rem">{run.name}</p>
        <p className="m-0">{formatDateRange(run.dates, true)}</p>
    </div>
);

interface ShowInfoProps {
    run: Run;
    show: Show;
}

export const ShowInfo: React.FC<ShowInfoProps> = ({run, show}) => (
    <div className="column--centered" id="run-info">
        <p className="mt-0 mb-10 text-125rem">{run.name}</p>
        <p className="m-0">Night {show.runNight} - {dateToString(show.date)} </p>
    </div>
);

