import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResponseStatus } from "../../app/store/store";
import { getAllRuns } from "../../api/Runs.api";
import { saveRuns, saveShows, selectShowsByRun, selectShowsByVenue, selectShowsByYear } from "./ShowList.store";
import LoadingSpinner from "../../shared/icons/LoadingSpinner";
import { ShowGroupRun, ShowGroupVenue, ShowGroupYear } from "../../types/Show.type";
import { getAllShows } from "../../api/Shows.api";
import { BackArrow } from "../../app/App.router";
import ShowList from "./ShowList";
import { toTitleCase } from "../../shared/util/utils";
import "./ShowList.scss";

export type FilterType = 'run' | 'year' | 'venue';
const filterTypes: FilterType[] = ['run', 'year', 'venue'];

const ShowListContainer: React.FC = () => {
    const showsByRun: ShowGroupRun[] = useSelector(selectShowsByRun);
    const showsByYear: ShowGroupYear[] = useSelector(selectShowsByYear);
    const showsByVenue: ShowGroupVenue[] = useSelector(selectShowsByVenue);
    const [ status, setStatus ] = useState<'loading' | 'loaded' | 'error'>('loading');
    const [ error, setError ] = useState<string | null>(null);
    const [ filterType, setFilterType ] = useState<FilterType>('run');
    const dispatch = useDispatch();
    
    const getShowInfo = async () => {
        const shows = await getAllShows();
        const runs = await getAllRuns();
        if (shows === ResponseStatus.UnknownError || runs === ResponseStatus.UnknownError) {
            setError('An unknown error occurred. Please try again or refresh the page.');
            setStatus('error');
            return;
        }
        dispatch(saveShows(shows));
        dispatch(saveRuns(runs));
        setStatus("loaded");
    }

    useEffect(() => {
        getShowInfo();
    }, [ dispatch ]);

    const FilterBar = () => (
        <div className="row--center mx-bw-10 mb-20">
            {
                filterTypes.map(filter => (
                    <p key={filter} className={`m-0 pointer pb-5 ${filter === filterType ? 'border-bottom border-black' : ''}`} onClick={() => setFilterType(filter)}>
                        By {toTitleCase(filter)}
                    </p>
                ))
            }
        </div>
    );
    

    return (
        <div className="column--centered">
            <div className="header-container">
                <p className="header">Shows</p>
                <BackArrow link="/" />
            </div>
            {
                status === 'loading' && <LoadingSpinner label="Loading Shows..." topPadding />
            }
            { error && <p className="mx-20 text-center">{error}</p> }
            {
                status === 'loaded' && showsByRun && showsByYear && showsByVenue &&
                <div className="w-100">
                    <FilterBar />
                    <ShowList filterType={filterType} showsByRun={showsByRun} showsByYear={showsByYear} showsByVenue={showsByVenue} />
                </div>
            }
        </div>
    )
}

export default ShowListContainer;
