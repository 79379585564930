import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ResponseStatus } from "../../app/store/store";
import { Guess, GuessID } from "../../types/Guess.type";
import { createGuess, deleteGuess, getGuessesForUserForShow } from "../../api/Guess.api";
import { getRunById } from "../../api/Runs.api";
import { Run, RunID } from "../../types/Run.type";
import { User, UserID } from "../../types/User.type";
import { Link, useSearchParams } from "react-router-dom";
import {RunInfo, ShowInfo} from "../../shared/component/RunInfo";
import LoadingSpinner from "../../shared/icons/LoadingSpinner";
import { checkUserCookie, selectUserId } from "../Authentication/Authentication.store";
import { addGuess, saveGuesses, selectGuesses, selectRun, selectShow, storeRun, storeShow, storeSongList } from "./GuessEditor.store";
import { getAllSongs } from "../../api/Song.api";
import { SongID } from "../../types/Song.type";
import { BackArrow } from "../../app/App.router";
import { Show, ShowID } from "../../types/Show.type";
import { getShowById } from "../../api/Shows.api";
import { isPastDate } from "../../shared/util/date.utils";
import GuessEditor from "./GuessEditor";

const GuessEditorContainer: React.FC = () => {
    const navigate = useNavigate();
    const { showId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const beta = searchParams.get('beta');

    const userId: UserID | null = useSelector(selectUserId);
    const [ error, setError ] = useState<string | null>(null);
    const [ status, setStatus ] = useState<'loading' | 'loaded' | 'error'>('loading');

    //const organizedGuesses: Record<UserID, {"complete": Guess[], "incomplete": Guess[]}> = useSelector(selectOrganizedGuessesByUser);
    //const organizedUsers: Record<UserID, User> = useSelector(selectUsersOrganized);
    //const guesses: Guess[] = useSelector(selectGuesses);
    const run: Run | null = useSelector(selectRun);
    const show: Show | null = useSelector(selectShow);
    const dispatch = useDispatch();

    const collectGuessListInfo = async (userId: UserID, showId: ShowID) => {
        if (!userId) return;
        const guesses = await getGuessesForUserForShow(userId, showId);
        if (guesses === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        const show = await getShowById(showId);
        if (show === ResponseStatus.NotFound) {
            setError("Run not found error");
            return;
        } else if (show === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        // check if current day is past show day
        if (isPastDate(show.date)) {
            //setError("This show has already happened! You can only add guesses for upcoming shows.");
            //setStatus("error");
            //return;
        }
        const run = await getRunById(show.runId);
        if (run === ResponseStatus.NotFound) {
            setError("Error: Run not found.");
            setStatus("error");
            return;
        } else if (run === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        const songList = await getAllSongs();
        if (songList === ResponseStatus.UnknownError) {
            setError("Unknown error");
            setStatus("error");
            return;
        }
        dispatch(saveGuesses(guesses));
        dispatch(storeRun(run));
        dispatch(storeShow(show));
        dispatch(storeSongList(songList));
        setStatus('loaded');
    }

    useEffect(() => {
        if (!userId) {
            dispatch(checkUserCookie());
            setError("You must be logged in to edit guesses.");
            setStatus("error");
        }
        if (!showId || isNaN(parseInt(showId))) {
            setError("Show not found");
            setStatus("error");
        } else {
            collectGuessListInfo(userId as UserID, parseInt(showId));
        }
    }, [ userId, showId, dispatch ]);

    
    return (
        <div id="guess-editor-page">
            <div className="column--centered">
                <div className="header-container">
                    <p className="header">Guesses</p>
                    <BackArrow />
                </div>
                {
                    status === 'loading' &&
                    <LoadingSpinner label="Loading Guesses..." />
                }
                {
                    status === 'loaded' && run && show &&
                    <>
                        <ShowInfo show={show} run={run} />
                        { /*!beta && <p className="pointer text-brick-red" onClick={() => setSearchParams({beta: 'true'})}>Try New Guess Editor</p> */}
                        <div className="w-100">
                            { /*beta ? <GuessEditorBeta allGuesses={guesses} /> : <GuessEditor allGuesses={guesses} /> */}
                            <GuessEditor /> 
                        </div>
                    </>
                }
                {
                    status === 'error' &&
                    <>
                        <p className="text-center">{error || "An unknown error occurred."}</p>
                        {
                            error && error.includes("logged in") &&
                            <Link to={showId ? `/login?redirect=guesses%2F${showId}%2Fedit` : '/login'}>Login</Link>
                        }
                    </>
                }
                <div className="h-20vh" />
            </div>
        </div>
    )
}

export default GuessEditorContainer;
