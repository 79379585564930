import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store/store";
import { Guess, GuessID } from "../../types/Guess.type";
import { Run } from "../../types/Run.type";
import { Show, ShowID } from "../../types/Show.type";
import { Song, SongID } from "../../types/Song.type";

export interface AdminState {
    allSongs: Song[],
    songList: {id: string, encore: boolean}[],
    shows: Show[],
    showId: ShowID | null,
}

const initialState: AdminState = {
    allSongs: [],
    songList: [],
    shows: [],
    showId: null,
}

const storeAllSongsReducer = (state: AdminState, action: PayloadAction<Song[]>): AdminState => ({
    ...state,
    allSongs: action.payload || []
});

const storeSongListReducer = (state: AdminState, action: PayloadAction<{id: string, encore: boolean}[]>): AdminState => ({
    ...state,
    songList: action.payload || []
});

const addSongReducer = (state: AdminState, action: PayloadAction<{id: string, encore: boolean}>): AdminState => ({
    ...state,
    songList: [...state.songList, action.payload]
});

const removeSongReducer = (state: AdminState, action: PayloadAction<SongID>): AdminState => ({
    ...state,
    songList: state.songList.filter(song => song.id !== action.payload)
});

const storeShowsReducer = (state: AdminState, action: PayloadAction<Show[]>): AdminState => ({
    ...state,
    shows: action.payload || []
});

const storeShowIdReducer = (state: AdminState, action: PayloadAction<ShowID | null>): AdminState => ({
    ...state,
    showId: action.payload
});

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        storeAllSongs: storeAllSongsReducer,
        storeSongList: storeSongListReducer,
        addSong: addSongReducer,
        removeSong: removeSongReducer,
        storeShows: storeShowsReducer,
        storeShowId: storeShowIdReducer,
    }
})

export const { storeAllSongs, storeSongList, addSong, removeSong, storeShows, storeShowId } = adminSlice.actions;

export const selectAllSongs = (state: RootState): Song[] => state.admin.allSongs;

export const selectSongList = (state: RootState): {id: string, encore: boolean}[] => {
    let songs = [...state.admin.songList];
    return songs.sort((songA, songB) => songA.encore ? songB.encore ? 0 : 1 : songB.encore ? -1: 0);
}

export const selectShows = (state: RootState): Show[] => {
    let shows = [...state.admin.shows];
    return shows.sort((s1, s2) => (new Date(s2.date).getTime() - (new Date(s1.date)).getTime()));
}

export const selectShowId = (state: RootState): ShowID | null => state.admin.showId;

export const adminReducer = adminSlice.reducer;