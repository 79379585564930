import { apiRoot, ResponseStatus } from "../app/store/store";
import { AvatarConfig } from "../domain/AvatarCreator/AvatarCreator.store";
import { User, UserID } from "../types/User.type";

export const getAllUsers = (): Promise<User[] | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/users`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('users')) {
                resolve(data.users as User[]);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}

export const getUserById = (userId: UserID): Promise<User | ResponseStatus.UnknownError | ResponseStatus.NotFound> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/users/${userId}`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('user')) {
                resolve(data.user as User);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else if (response.status === 404) {
            resolve(ResponseStatus.NotFound);
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}

export const getUserByEmail = (email: string): Promise<User | ResponseStatus.UnknownError | ResponseStatus.NotFound> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/users?email=${email}`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('user')) {
                resolve(data.user as User);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else if (response.status === 404) {
            resolve(ResponseStatus.NotFound);
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}

export const updateAvatarForUser = (userId: UserID, avatar: AvatarConfig): Promise<ResponseStatus.Success | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/users/${userId}/avatar`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({ avatar })
        });
        if (response.status === 200) {
            resolve(ResponseStatus.Success)
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}

export const getUserListByIds = (userIds: UserID[]): Promise<User[] | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/users?userIds=${userIds.join('_')}`);
        if (response.status === 200) {
            const data = await response.json();
            if (data.hasOwnProperty('users')) {
                resolve(data.users as User[]);
            } else {
                resolve(ResponseStatus.UnknownError);
            }
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}

export const sendRecoveryCode = (email: string, code: string): Promise<ResponseStatus.Success | ResponseStatus.UnknownError> => {
    return new Promise(async (resolve) => {
        const response = await fetch(`${apiRoot}/recovery-code`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({ email, code })
        });
        if (response.status === 200) {
            resolve(ResponseStatus.Success)
        } else {
            resolve(ResponseStatus.UnknownError);
        }
    })
}