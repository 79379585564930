import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import Login from "../domain/Authentication/Login";
import Signup from "../domain/Authentication/Signup";
import GuessListContainer from "../domain/GuessList/GuessListContainer";
import RunList from "../domain/RunList/RunList";
import LeaderboardContainer from "../domain/Leaderboard/LeaderboardContainer";
import ProfilePage from "../domain/ProfilePage/ProfilePage";
import Home from "./component/Home";
import GuessEditorContainer from "../domain/GuessEditor/GuessEditorContainer";
import ShowListContainer from "../domain/ShowList/ShowListContainer";
import { ReactComponent as LeftArrow } from "../shared/icons/LeftArrow.svg";
import AdminContainer from "../domain/Admin/AdminContainer";
import AvatarCreatorContainer from "../domain/AvatarCreator/AvatarCreatorContainer";
import RunGuessListContainer from "../domain/RunGuessList/RunGuessListContainer";
import Today from "../domain/Today/Today";
import ForgotPassword from "../domain/Authentication/ForgotPassword";

interface BackArrowProps {
    link?: string;
}
export const BackArrow: React.FC<BackArrowProps> = ({ link }) => {
    const navigate = useNavigate();
    return <div onClick={() => navigate(link || '/shows')} className="pointer"><LeftArrow /></div>
}

const AppRouter: React.FC = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/runs" element={<RunList />} />
        <Route path="/shows" element={<ShowListContainer />} />
        <Route path="/admin" element={<AdminContainer />} />
        <Route path="/today" element={<Today />} />
        { /*<Route path="/runs/:runId" element={<div />} /> */ }
        { /* <Route path="/guesses" element={<div />} /> */ }
        {/*<Route path="/guesses/:runId" element={<GuessListContainer />} />
        <Route path="/guesses/:runId/edit" element={<GuessEditorContainer />} />
        <Route path="/scores/:runId" element={<LeaderboardContainer />} />*/}
        {/*<Route path="/guesses/:showId" element={<GuessListContainer />} />*/}
        <Route path="/guesses/run/:runId" element={<RunGuessListContainer />} />
        <Route path="/guesses/:showId/edit" element={<GuessEditorContainer />} />
        <Route path="/scores/:runId" element={<LeaderboardContainer />} />
        <Route path="/users/:userId" element={<ProfilePage />} />
        <Route path="/avatar-creator" element={<AvatarCreatorContainer />} />
    </Routes>
);

export default AppRouter;
