import React, { useState } from "react";
import { Color, randomHex } from "../../shared/util/colors";
import { AvatarConfig, defaultAvatar } from "./AvatarCreator.store";
import { AvatarIconSized } from "./AvatarIcon";
import { ChromePicker } from "react-color";

interface AvatarCreatorProps {
    initHead: Color;
    initTorso: Color;
    initBackground: Color;
    save: (avatar: AvatarConfig) => void;
}

const AvatarCreator: React.FC<AvatarCreatorProps> = ({ initHead, initTorso, initBackground, save }) => {
    const [ head, setHead ] = useState<Color>(initHead);
    const [ torso, setTorso ] = useState<Color>(initTorso);
    const [ background, setBackground ] = useState<Color>(initBackground);
    const [ openColor, setOpenColor ] = useState<'head' | 'torso' | 'background' | null>(null);

    const randomColors = () => {
        setHead(randomHex());
        setTorso(randomHex());
        setBackground(randomHex());
    }

    return (
        <div className="column--centered mt-20 w-100">
            <AvatarIconSized head={head} torso={torso} background={background} size={200} />
            <div className="column--centered w-100">
                <div className="row--space-between align-center w-75 mx-bw-10">
                    <p>Head:</p>
                    <p style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', color: head}}>{head}</p>
                    <p onClick={() => setOpenColor(c => c === "head" ? null : "head")} className="button">{openColor === "head" ? "Save" : "Edit"}</p>
                    <p onClick={() => setHead(randomHex())} className="button">Random</p>
                </div>
                { openColor === "head" && <ChromePicker color={head} onChangeComplete={color => setHead(color.hex as Color)} /> }
                <div className="row--space-between align-center w-75 mx-bw-10">
                    <p>Torso:</p>
                    <p style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', color: torso}}>{torso}</p>
                    <p onClick={() => setOpenColor(c => c === "torso" ? null : "torso")} className="button">{openColor === "torso" ? "Save" : "Edit"}</p>
                    <p onClick={() => setTorso(randomHex())} className="button">Random</p>
                </div>
                { openColor === "torso" && <ChromePicker color={torso} onChangeComplete={color => setTorso(color.hex as Color)} /> }
                <div className="row--space-between align-center w-75 mx-bw-10">
                    <p>Background:</p>
                    <p style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', color: background}}>{background}</p>
                    <p onClick={() => setOpenColor(c => c === "background" ? null : "background")} className="button">{openColor === "background" ? "Save" : "Edit"}</p>
                    <p onClick={() => setBackground(randomHex())} className="button">Random</p>
                </div>
                { openColor === "background" && <ChromePicker color={background} onChangeComplete={color => setBackground(color.hex as Color)} /> }
            </div>
            <p onClick={randomColors} className="button">Shuffle Random</p>
            <p className="button" onClick={() => save({head, torso, background})}>Save</p>
        </div>
    );
}

export default AvatarCreator;
