import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store/store";
import { attemptLogin } from "../../api/Authentication.api";
import { User, UserID } from "../../types/User.type";

export interface AuthenticationState {
    loggedIn: boolean,
    userId: UserID | null
}

const initialState: AuthenticationState = {
    loggedIn: false,
    userId: null
    //userId: JSON.parse(window.localStorage.getItem('gp-user') || 'null')
}

const checkUserCookieReducer = (state: AuthenticationState) => {
    const userCookie = JSON.parse(window.localStorage.getItem('gp-user') || 'null') as User;
    return ({
        ...state,
        loggedIn: userCookie !== null,
        userId: userCookie ? userCookie.id : null
    });
}

const logInReducer = (state: AuthenticationState, action: PayloadAction<UserID>) => ({
    ...state,
    loggedIn: true,
    userId: action.payload
});

const logOutReducer = (state: AuthenticationState) => ({
    ...state,
    loggedIn: false,
    userId: null
});

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        logIn: logInReducer,
        logOut: logOutReducer,
        checkUserCookie: checkUserCookieReducer,
    }
})

export const { logIn, logOut, checkUserCookie } = authenticationSlice.actions;

/*
export const tryLogin = createAsyncThunk(
    'authentication/attemptLogin',
    async (credentials: {username: string, password: string}) => {
      const response = await attemptLogin(credentials.username, credentials.password)
      
      return response;
    }
  );
*/

export const selectIsLoggedIn = (state: RootState): boolean => state.authentication.loggedIn;
export const selectUserId = (state: RootState): UserID | null => state.authentication.userId;

export const authenticationReducer = authenticationSlice.reducer;
