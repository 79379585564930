import { Guess } from "../../types/Guess.type";
import { emptyUser, User, UserID } from "../../types/User.type";
import { compareTwoStrings } from "string-similarity";

export const stringSimilarity = (string1: string, string2: string): number => compareTwoStrings(string1, string2);

export const toTitleCase = (str: string): string => str.split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');

export const arrayToDictByID = <T extends {id: string|number}>(arr: T[]): Record<string, T> => {
    let dict: Record<string|number, T> = {};
    arr.forEach(item => {
        dict[item.id || ''] = item;
    })
    return dict;
}

export const organizeArrayByField = <T extends Record<string, any>>(arr: T[], field: string): Record<string, T[]> => {
    let dict: Record<string, T[]> = {};
    arr.forEach(item => {
        if (field in item) {
            if (item[field] in dict) {
                dict[item[field]].push(item);
            } else {
                dict[item[field]] = [ item ];
            }
        }
    });
    return dict;
}

export const totalScoresForRuns = (organizedScores: Record<string, Guess[]>): Record<string, number> => {
    let scoresForRuns: Record<string, number> = {};
    Object.entries(organizedScores).forEach(([runId, scores]: [string, Guess[]]) => {
        scoresForRuns[runId] = scores.map(score => score.points).reduce((acc, curr) => acc + curr);
    });
    return scoresForRuns;
}

export const organizeGuessesByUserId = (guesses: Guess[]): Record<UserID, Guess[]> => {
    let guessesDict: Record<UserID, Guess[]> = {};
    guesses.forEach(guess => {
        if (guess.userId in guessesDict) {
            guessesDict[guess.userId].push(guess);
        } else {
            guessesDict[guess.userId] = [ guess ];
        }
    });

    return guessesDict;
}

export const organizeScoresByUserId = (scores: Guess[]): Record<UserID, Guess[]> => {
    let scoresDict: Record<UserID, Guess[]> = {};
    scores.forEach(score => {
        if (score.userId in scoresDict) {
            scoresDict[score.userId].push(score);
        } else {
            scoresDict[score.userId] = [ score ];
        }
    });

    return scoresDict;
}

export const rankScores = (scores: Guess[]): ({userId: UserID, points: number})[] => {
    const scoresDict: Record<UserID, Guess[]> = organizeScoresByUserId(scores.filter(s => s.completed));
    let scoresRanked: ({userId: UserID, points: number})[] = [];
    Object.keys(scoresDict).forEach(userId => {
        scoresRanked.push({userId: parseInt(userId), points: scoresDict[parseInt(userId)].map(score => score.points).reduce((acc, curr) => acc + curr)});
    })
    return scoresRanked.sort((a, b) => a.points - b.points);
}

export const rankScoresObj = (scores: Guess[], users: User[]): ({user: User, points: number})[] => {
    const scoresDict: Record<UserID, Guess[]> = organizeArrayByField(scores.filter(s => s.completed), "userId");
    let scoresRanked: ({user: User, points: number})[] = [];
    Object.keys(scoresDict).forEach(userId => {
        scoresRanked.push({
            user: users.find(u => u.id == parseInt(userId)) || emptyUser, 
            points: scoresDict[parseInt(userId)].map(score => score.points).reduce((acc, curr) => acc + curr)
        });
    })
    return scoresRanked.sort((a, b) => b.points - a.points);
}

export const rankUsers = (scores: Guess[]): Record<UserID, number> => {
    const scoresDict: Record<UserID, Guess[]> = organizeScoresByUserId(scores);
    const usersDict: Record<UserID, number> = {};
    Object.keys(scoresDict).forEach(userId => {
        usersDict[parseInt(userId)] = scoresDict[parseInt(userId)].map(score => score.points).reduce((acc, curr) => acc + curr);
    })
    return usersDict;
}

/*
    completed, non-encore,
    completed, encore,
    uncompleted, non-encore
    uncompleted, encore
*/
export const guessSorter = (a: Guess, b: Guess): number => {
    if (a.completed && !b.completed) return -1;
    else if (b.completed && !a.completed) return 1;
    else if (a.completed && b.completed) {
        if (a.encore && !b.encore) return 1;
        else if (b.encore && !a.encore) return -1;
    } else if (!a.completed && !b.completed) { 
        if (a.encore && !b.encore) return 1;
        else if (b.encore && !a.encore) return -1;
    }
    return 0;
}

export const generateCode = (length: number): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}