import { Color } from "../../shared/util/colors"
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Selector } from "react-redux";
import { RootState } from "../../app/store/store";
import { User } from "../../types/User.type";

export interface AvatarConfig {
    head: Color,
    torso: Color,
    background: Color
}

export const defaultAvatar: AvatarConfig = {
    head: "#ddd",
    torso: "#aaa",
    background: '#666'
}

export interface AvatarCreatorState {
    user: User | null;
}

const initialState: AvatarCreatorState = {
    user: null,
}

const saveUserReducer = (state: AvatarCreatorState, action: PayloadAction<User>): AvatarCreatorState => ({
    ...state,
    user: action.payload || null
});

export const avatarCreatorSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveUser: saveUserReducer,
    }
})

export const { saveUser } = avatarCreatorSlice.actions;

export const selectUser: Selector<RootState, User | null> = state => state.profilePage.user;

export const avatarCreatorReducer = avatarCreatorSlice.reducer;
