import { AvatarConfig, defaultAvatar } from "../domain/AvatarCreator/AvatarCreator.store";

export type UserID = number;

export interface User {
    id: UserID,
    username: string,
    password: string,
    avatar: AvatarConfig,
    admin: boolean
    email: string;
}

export const emptyUser: User = {
    id: 0,
    username: "",
    password: "",
    avatar: defaultAvatar,
    admin: false,
    email: "",
}
