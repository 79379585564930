import { useState } from "react";
import { Song, SongID } from "../../types/Song.type";
import { stringSimilarity } from "../../shared/util/utils";

interface SongInputProps {
    selectedSong: SongID | null,
    songList: Song[];
    submitGuess: (song: SongID) => void;
}

const SongInput: React.FC<SongInputProps> = ({ songList, selectedSong, submitGuess}) => {
    const [ songInput, setSongInput ] = useState('');
    const [ songResults, setSongResults ] = useState<Song[]>([]);
    const allSongs = [...songList];

    const searchSong = (query: string) => {
        if (query === "") {
            setSongResults([]);
            return;
        }
        let songs = allSongs.filter(song => song.name.toLowerCase().includes(query.toLowerCase()));
        //let songs = allSongs.filter(song => song.name.toLowerCase().startsWith(query.toLowerCase()));
        songs = songs.sort((song1, song2) => stringSimilarity(song2.name, query) - stringSimilarity(song1.name, query));

        const topSongs = songs.slice(0, 3);
        setSongResults(topSongs);
    }

    const chooseSong = (song: SongID) => {
        submitGuess(song);
    }

    return (
        <div className="song-input column--flex-start">
            <input className="song-choice border-top-0 content-box " type="text" value={songInput} onChange={e => {setSongInput(e.target.value); searchSong(e.target.value)}} placeholder="Song Name" />
            {
                songResults.length > 0 &&
                <div className="column--flex-start my-bw-10" style={{padding: '10px'}}>
                    {
                        songResults.map(song => (
                            <div key={song.id} onClick={() => chooseSong(song.id)} className={`result-item ${selectedSong === song.id ? 'selected' : ''}`}>
                                <p className="m-0">{song.name}</p>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    );
}

export default SongInput;
